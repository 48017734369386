<template>
  <v-sheet class="text-start">
    <page-head
      title="Create Workday"
      :breadcrumb="['Shop', 'Workdays', 'Create']"
    >
    </page-head>
    <v-form class="ma-3" ref="form" @submit.prevent="onFormSubmit">
      <h2 class="my-5">Workday</h2>
      <v-date-input
        label="Date"
        variant="outlined"
        prepend-icon=""
        prepend-inner-icon="$calendar"
        v-model="dateValue"
      ></v-date-input>
      <v-select
        label="Branchs"
        variant="outlined"
        :items="branchs"
        :rules="RULES.select"
        multiple
        chips
        v-model="branchValue"
      >
      </v-select>
      <v-select
        label="Schedule"
        variant="outlined"
        :items="WEEKLY_SCHEDULE_ITEMS"
        :rules="RULES.select"
        v-model="scheduleValue"
      >
      </v-select>
      <v-btn class="mt-2" type="submit" variant="outlined" block color="primary"
        >Create</v-btn
      >
    </v-form>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { authAxios } from "@/util/Axios";
import { reactive, ref } from "vue";
import { RULES } from "@/util/Form";
import { useRouter } from "vue-router";
import BackendConnector from "@/util/BackendConnector";
import { useUserProfile } from "@/util/UserProfile";
import moment from "moment";

const WEEKLY_SCHEDULE_ITEMS = [
  { title: "Weekday", value: "weekday" },
  { title: "Weekend", value: "weekend" },
  { title: "Close", value: "close" },
];

const axios = authAxios();
const router = useRouter();

const branchs = reactive([]);

const profile = reactive({});
useUserProfile().then((response) => {
  Object.assign(profile, response);
  BackendConnector.loadBranchs(profile.currentShopId).then((response) => {
    Object.assign(branchs, [...response]);
  });
});

const form = ref(null);
const dateValue = ref(null);
const branchValue = ref([]);
const scheduleValue = ref(null);

async function onFormSubmit() {
  const { valid } = await form.value.validate();
  if (valid) {
    submitCreateBranch(
      dateValue.value,
      branchValue.value,
      scheduleValue.value
    ).then(() => {
      router.go(-1);
    });
  }
}

function submitCreateBranch(date, branchIds, schedule) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/workday`,
    data: {
      date: moment(date).format("YYYY-MM-DD"),
      branch_ids: branchIds,
      schedule,
    },
  });
}
</script>
