<template>
  <v-sheet class="text-start">
    <page-head
      title="TopUp Packages"
      :breadcrumb="['Market', 'TopUp Packages']"
    >
      <template v-slot>
        <v-btn variant="outlined" color="primary" :to="{ name: 'topUpCreate' }"
          ><v-icon>mdi-plus</v-icon>Create TopUp Package</v-btn
        >
      </template>
    </page-head>
    <v-data-table-server
      :items="dataTable.items"
      :items-per-page="dataTable.itemsPerPage"
      :items-length="dataTable.totalItems"
      :headers="dataTable.headers"
      :loading="dataTable.isLoading"
      :items-per-page-options="dataTable.itemsPerPageOptions"
      @update:options="updatePackage"
    >
      <template v-slot:[`item.name`]="{ value, item }">
        <a :href="`/management/package/id-${item.id}`">
          {{ value }}
        </a></template
      ></v-data-table-server
    >
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import BackendConnector from "@/util/BackendConnector";
import { reactive } from "vue";
import { DEFAULT_DATATABLE } from "@/util/List";

const dataTable = reactive({
  headers: [
    {
      title: "Paid Amount",
      key: "paid_amount",
      align: "start",
      sortable: false,
    },
    {
      title: "Received Amount",
      key: "received_amount",
      align: "start",
      sortable: false,
    },
  ],
  ...DEFAULT_DATATABLE,
});

function updatePackage({ page, itemsPerPage }) {
  dataTable.isLoading = true;
  BackendConnector.loadPackageVuetify(page, itemsPerPage).then((response) => {
    dataTable.isLoading = false;
    dataTable.items = [...response.data.data];
    dataTable.totalItems = response.data.total_items;
  });
}
</script>
