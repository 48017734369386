<template>
  <v-sheet class="text-start">
    <page-head title="Fund Packages" :breadcrumb="['Market', 'Fund Packages']">
      <template v-slot>
        <v-btn
          variant="outlined"
          color="primary"
          :to="{ name: 'packageCreate' }"
          ><v-icon>mdi-plus</v-icon>Create Fund Package</v-btn
        >
      </template>
    </page-head>
    <v-data-table-server
      :items="dataTable.items"
      :items-per-page="dataTable.itemsPerPage"
      :items-length="dataTable.totalItems"
      :headers="dataTable.headers"
      :loading="dataTable.isLoading"
      :items-per-page-options="dataTable.itemsPerPageOptions"
      @update:options="updatePackage"
    >
      <template v-slot:[`item.name`]="{ value, item }">
        <a :href="`/management/package/id-${item.id}`">
          {{ value }}
        </a></template
      ></v-data-table-server
    >
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { reactive } from "vue";
import { authAxios } from "@/util/Axios";
import { DEFAULT_DATATABLE } from "@/util/List";

const axios = authAxios();
const dataTable = reactive({
  headers: [
    { title: "Name", key: "name", align: "start", sortable: false },
    { title: "Price", key: "price", align: "end", sortable: false },
    {
      title: "Linked Fund",
      key: "linekd_member_fund_name",
      align: "start",
      sortable: false,
    },
    { title: "Value", key: "value", align: "center", sortable: false },
    {
      title: "Duration (days)",
      key: "usage_duration",
      align: "center",
      sortable: false,
    },
  ],
  ...DEFAULT_DATATABLE,
});

function updatePackage({ page, itemsPerPage }) {
  dataTable.isLoading = true;
  loadPackage(page, itemsPerPage).then((response) => {
    dataTable.isLoading = false;
    dataTable.items = [...response.data.data];
    dataTable.totalItems = response.data.total_items;
  });
}

function loadPackage(page, length) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/sale_package/vuetify`,
    params: { page: page, length: length, search: "" },
  });
}
</script>
