<template>
  <v-sheet class="text-start">
    <page-head title="Report" :breadcrumb="['Open Private', 'Report']">
      <template v-slot>
        <v-btn
          variant="outlined"
          color="primary"
          :to="{ name: 'openPrivateIssueReport' }"
          ><v-icon>mdi-file-document-outline</v-icon>Issue Report</v-btn
        >
      </template>
    </page-head>
    <v-data-table-server
      :items="dataTable.items"
      :items-per-page="dataTable.itemsPerPage"
      :items-length="dataTable.totalItems"
      :headers="dataTable.headers"
      :loading="dataTable.isLoading"
      :items-per-page-options="dataTable.itemsPerPageOptions"
      @update:options="updateReport"
    >
    </v-data-table-server>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { reactive } from "vue";
import { DEFAULT_DATATABLE } from "@/util/List";
import { authAxios } from "@/util/Axios";
import moment from "moment";

const axios = authAxios();

const dataTable = reactive({
  headers: [
    { title: "Attendee", key: "attendee", align: "start", sortable: false },
    { title: "Create DT", key: "create_dt", align: "start", sortable: false },
    { title: "Branch", key: "branch", align: "start", sortable: false },
    { title: "Instructor", key: "instructor", align: "start", sortable: false },
    { title: "DateTime", key: "display_dt", align: "start", sortable: false },
    { title: "Status", key: "status", align: "start", sortable: false },
  ],
  ...DEFAULT_DATATABLE,
});

function updateReport({ page, itemsPerPage }) {
  dataTable.isLoading = true;
  loadReport(page, itemsPerPage).then((response) => {
    var result = [];
    dataTable.isLoading = false;
    response.data.data.forEach((item) => {
      result.push({
        ...item,
        create_dt: moment(item.transaction_start_local_dt).format(
          "MMM DD, YYYY HH:mm"
        ),
        display_dt: moment(item.datetime).format("MMM DD, YYYY HH:mm"),
      });
    });
    dataTable.items = [...result];
    dataTable.totalItems = response.data.total_items;
  });
}

function loadReport(page, length) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/open_private_booking/vuetify`,
    params: { page: page, length: length, search: "" },
  });
}
</script>
