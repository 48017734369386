<template>
  <v-sheet class="text-start" v-if="config">
    <page-head
      title="Settings"
      :breadcrumb="['Open Private', 'Settings']"
    ></page-head>
    <v-tabs v-model="tab" color="red-darken-4">
      <v-tab value="detail">Detail</v-tab>
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <h3 class="mb-3">Liff Connection</h3>
            <patch-input
              label="Liff ID"
              patch-key="liff_id"
              :default="config.liff_id"
              :update-func="onUpdate"
            ></patch-input>
            <h3 class="mb-3">Reservation</h3>
            <patch-input
              label="Longest Reservation Allow (days)"
              patch-key="longest_reservation_allow"
              :default="config.longest_reservation_allow"
              :update-func="onUpdate"
            ></patch-input>
            <h3 class="mb-3">Cancelation</h3>
            <patch-input
              label="Shortest Cancelation Allow (hours)"
              patch-key="shortest_cancelation_allow"
              :default="config.shortest_cancelation_allow"
              :update-func="onUpdate"
            ></patch-input>
          </v-container>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchInput from "@/components/admin_theme/patch/PatchInput.vue";
import { authAxios } from "@/util/Axios";
import BackendConnector from "@/util/BackendConnector";
import { useUserProfile } from "@/util/UserProfile";
import { ref } from "vue";

const axios = authAxios();
const tab = ref(null);

const config = ref(null);

let shopId = null;

useUserProfile().then((response) => {
  shopId = response.currentShopId;
  BackendConnector.loadOpenPrivateConfig(shopId).then((response) => {
    config.value = { ...response.data };
  });
});

function onUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/open_private_config/shop-${shopId}`,
    data: { [key]: value },
  });
}
</script>
