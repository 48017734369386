<template>
  <v-container>
    <v-row class="my-5">
      <v-col
        cols="6"
        class="pa-1"
        v-for="i in items"
        :key="i"
        @click="onCardClicked(i.paid_amount)"
      >
        <div
          v-ripple
          class="rounded-lg card pa-2"
          :class="{ active: props.currentAmount == i.paid_amount }"
        >
          <div class="d-flex">
            <div class="card-label">PAY</div>
            <div class="card-amount ms-auto">{{ i.paid_amount }}</div>
          </div>
          <v-divider></v-divider>
          <div class="d-flex">
            <div class="card-label">RECEIVED</div>
            <div class="card-amount ms-auto">{{ i.received_amount }}</div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import axios from "axios";
import { defineProps, defineEmits, ref } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const props = defineProps(["currentAmount"]);
const emits = defineEmits(["click"]);

const items = ref([]);

loadPackage(route.params.shopId).then((response) => {
  items.value = response.data;
});

function onCardClicked(amount) {
  emits("click", amount);
}

function loadPackage(shopId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/top_up_package`,
    params: { shop_id: shopId },
  });
}
</script>

<style scoped>
.card {
  border: 1px solid black;
}

.active {
  background-color: rgba(0, 0, 0, 0.2);
}

.card-label {
  font-size: 0.8rem;
}
.card-amount {
  font-size: 1.2rem;
}
</style>
