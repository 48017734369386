<template>
  <v-sheet class="text-start">
    <page-head
      title="Create TopUp Package"
      :breadcrumb="['Market', 'TopUp Packages', 'Create']"
    >
    </page-head>
    <v-form class="ma-3" ref="form" @submit.prevent="onFormSubmit">
      <h2 class="my-5">Package</h2>
      <v-text-field
        label="Paid Amount"
        :rules="RULES.digit"
        v-model="paidAmountValue"
        variant="outlined"
      ></v-text-field>
      <v-text-field
        label="Received Amount"
        :rules="RULES.digit"
        v-model="receivedAmountValue"
        variant="outlined"
      ></v-text-field>
      <v-btn class="mt-2" type="submit" variant="outlined" block color="primary"
        >Create</v-btn
      >
    </v-form>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { authAxios } from "@/util/Axios";
import { ref } from "vue";
import { RULES } from "@/util/Form";
import { useRouter } from "vue-router";

const axios = authAxios();
const router = useRouter();

const form = ref(null);
const paidAmountValue = ref(null);
const receivedAmountValue = ref(null);

async function onFormSubmit() {
  const { valid } = await form.value.validate();
  if (valid) {
    createTopUpPackage(paidAmountValue.value, receivedAmountValue.value).then(
      () => {
        router.go(-1);
      }
    );
  }
}

function createTopUpPackage(paid_amount, received_amount) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/top_up_package`,
    data: { paid_amount, received_amount },
  });
}
</script>
