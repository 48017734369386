import BaseThemeView from "@/views/admin_theme/BaseThemeView.vue";
import LoginView from "@/views/management/LoginView.vue";
import DashboardView from "@/views/management/DashboardView.vue";
import sideBar from "@/components/management/SideBar.vue";
import appBar from "@/components/management/AppBar.vue";

import TopicList from "@/views/management/class/topic/ListView.vue";
import TopicCreate from "@/views/management/class/topic/CreateView.vue";
import TopicDetail from "@/views/management/class/topic/DetailView.vue";

import InstructorList from "@/views/management/class/instructor/ListView.vue";
import InstructorCreate from "@/views/management/class/instructor/CreateView.vue";
import InstructorDetail from "@/views/management/class/instructor/DetailView.vue";

import ClassReport from "@/views/management/class/ClassReport.vue";
import ClassIssueReport from "@/views/management/class/IssueReport.vue";
import ClassSettings from "@/views/management/class/ClassSettings.vue";

import ResourceList from "@/views/management/facility/resource/ListView.vue";
import ResourceCreate from "@/views/management/facility/resource/CreateView.vue";
import ResourceDetail from "@/views/management/facility/resource/DetailView.vue";

import FacilityReport from "@/views/management/facility/FacilityReport.vue";
import FacilityIssueReport from "@/views/management/facility/IssueReport.vue";
import FacilitySettings from "@/views/management/facility/FacilitySettings.vue";

import OpenPrivateReport from "@/views/management/open_private/OpenPrivateReport.vue";
import OpenPrivateIssueReport from "@/views/management/open_private/IssueReport.vue";
import OpenPrivateSettings from "@/views/management/open_private/OpenPrivateSettings.vue";

import FundList from "@/views/management/membership/fund/ListView.vue";
import FundCreate from "@/views/management/membership/fund/CreateView.vue";
import FundDetail from "@/views/management/membership/fund/DetailView.vue";

import TopUpList from "@/views/management/market/top_up_package/ListView.vue";
import TopUpCreate from "@/views/management/market/top_up_package/CreateView.vue";

import PackageList from "@/views/management/market/fund_package/ListView.vue";
import PackageCreate from "@/views/management/market/fund_package/CreateView.vue";
import PackageDetail from "@/views/management/market/fund_package/DetailView.vue";

import PromotionGroupList from "@/views/management/market/promotion_group/ListView.vue";
import PromotionGroupCreate from "@/views/management/market/promotion_group/CreateView.vue";
import PromotionGroupDetail from "@/views/management/market/promotion_group/DetailView.vue";

import BranchList from "@/views/management/shop/branch/ListView.vue";
import BranchCreate from "@/views/management/shop/branch/CreateView.vue";
import BranchDetail from "@/views/management/shop/branch/DetailView.vue";

import WorkdayList from "@/views/management/shop/workday/ListView.vue";
import WorkdayCreate from "@/views/management/shop/workday/CreateView.vue";

import TeamList from "@/views/management/shop/team/ListView.vue";
import TeamInvite from "@/views/management/shop/team/InviteView.vue";
import TeamDetail from "@/views/management/shop/team/DetailView.vue";

import ShopSettings from "@/views/management/shop/SettingsView.vue";

export const managementLoginRouter = {
  path: "/management/login",
  name: "managementLogin",
  component: LoginView,
};

export const managementRouter = {
  path: "/management",
  component: BaseThemeView,
  children: [
    {
      path: "dashboard",
      name: "managementDashboard",
      components: { default: DashboardView, sideBar, appBar },
    },
    {
      path: "topic",
      children: [
        {
          path: "",
          name: "topicList",
          components: { default: TopicList, sideBar, appBar },
        },
        {
          path: "create",
          name: "topicCreate",
          components: { default: TopicCreate, sideBar, appBar },
        },
        {
          path: "id-:topicId",
          name: "topicDetail",
          components: { default: TopicDetail, sideBar, appBar },
        },
      ],
    },
    {
      path: "instructor",
      children: [
        {
          path: "",
          name: "instructorList",
          components: { default: InstructorList, sideBar, appBar },
        },
        {
          path: "create",
          name: "instructorCreate",
          components: { default: InstructorCreate, sideBar, appBar },
        },
        {
          path: "id-:instructorId",
          name: "instructorDetail",
          components: { default: InstructorDetail, sideBar, appBar },
        },
      ],
    },
    {
      path: "class-report",
      children: [
        {
          path: "",
          name: "classReport",
          components: { default: ClassReport, sideBar, appBar },
        },
        {
          path: "issue-report",
          name: "classIssueReport",
          components: { default: ClassIssueReport, sideBar, appBar },
        },
      ],
    },
    {
      path: "class-settings",
      name: "classSettings",
      components: { default: ClassSettings, sideBar, appBar },
    },
    {
      path: "resource",
      children: [
        {
          path: "",
          name: "resourceList",
          components: { default: ResourceList, sideBar, appBar },
        },
        {
          path: "create",
          name: "resourceCreate",
          components: { default: ResourceCreate, sideBar, appBar },
        },
        {
          path: "id-:resourceId",
          name: "resourceDetail",
          components: { default: ResourceDetail, sideBar, appBar },
        },
      ],
    },
    {
      path: "facility-report",
      children: [
        {
          path: "",
          name: "facilityReport",
          components: { default: FacilityReport, sideBar, appBar },
        },
        {
          path: "issue-report",
          name: "facilityIssueReport",
          components: { default: FacilityIssueReport, sideBar, appBar },
        },
      ],
    },
    {
      path: "facility-settings",
      name: "facilitySettings",
      components: { default: FacilitySettings, sideBar, appBar },
    },
    {
      path: "open-private-report",
      children: [
        {
          path: "",
          name: "openPrivateReport",
          components: { default: OpenPrivateReport, sideBar, appBar },
        },
        {
          path: "issue-report",
          name: "openPrivateIssueReport",
          components: { default: OpenPrivateIssueReport, sideBar, appBar },
        },
      ],
    },
    {
      path: "open-private-settings",
      name: "openPrivateSettings",
      components: { default: OpenPrivateSettings, sideBar, appBar },
    },
    {
      path: "fund",
      children: [
        {
          path: "",
          name: "fundList",
          components: { default: FundList, sideBar, appBar },
        },
        {
          path: "create",
          name: "fundCreate",
          components: { default: FundCreate, sideBar, appBar },
        },
        {
          path: "id-:fundId",
          name: "fundDetail",
          components: { default: FundDetail, sideBar, appBar },
        },
      ],
    },
    {
      path: "top-up",
      children: [
        {
          path: "",
          name: "topUpList",
          components: { default: TopUpList, sideBar, appBar },
        },
        {
          path: "create",
          name: "topUpCreate",
          components: { default: TopUpCreate, sideBar, appBar },
        },
      ],
    },
    {
      path: "package",
      children: [
        {
          path: "",
          name: "packageList",
          components: { default: PackageList, sideBar, appBar },
        },
        {
          path: "create",
          name: "packageCreate",
          components: { default: PackageCreate, sideBar, appBar },
        },
        {
          path: "id-:packageId",
          name: "packageDetail",
          components: { default: PackageDetail, sideBar, appBar },
        },
      ],
    },
    {
      path: "promotion-group",
      children: [
        {
          path: "",
          name: "promotionGroupList",
          components: { default: PromotionGroupList, sideBar, appBar },
        },
        {
          path: "create",
          name: "promotionGroupCreate",
          components: { default: PromotionGroupCreate, sideBar, appBar },
        },
        {
          path: "id-:promotionGroupId",
          name: "promotionGroupDetail",
          components: { default: PromotionGroupDetail, sideBar, appBar },
        },
      ],
    },
    {
      path: "branch",
      children: [
        {
          path: "",
          name: "branchList",
          components: { default: BranchList, sideBar, appBar },
        },
        {
          path: "create",
          name: "branchCreate",
          components: { default: BranchCreate, sideBar, appBar },
        },
        {
          path: "id-:branchId",
          name: "branchDetail",
          components: { default: BranchDetail, sideBar, appBar },
        },
      ],
    },
    {
      path: "workday",
      children: [
        {
          path: "",
          name: "workdayList",
          components: { default: WorkdayList, sideBar, appBar },
        },
        {
          path: "create",
          name: "workdayCreate",
          components: { default: WorkdayCreate, sideBar, appBar },
        },
      ],
    },
    {
      path: "team",
      children: [
        {
          path: "",
          name: "teamList",
          components: { default: TeamList, sideBar, appBar },
        },
        {
          path: "invite",
          name: "teamInvite",
          components: { default: TeamInvite, sideBar, appBar },
        },
        {
          path: "id-:userId",
          name: "teamDetail",
          components: { default: TeamDetail, sideBar, appBar },
        },
      ],
    },
    {
      path: "shop/settings",
      name: "shopSettings",
      components: { default: ShopSettings, sideBar, appBar },
    },
  ],
};
