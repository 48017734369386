import BaseEvent from "@/views/BaseEventView.vue";
import LineConnectView from "@/views/LineConnectView.vue";
import OpenPrivateInfoView from "@/views/open_private_event/OpenPrivateInfoView.vue";
import OpenPrivateSummaryView from "@/views/open_private_event/OpenPrivateSummaryView.vue";
import InvalidForBookingView from "@/views/InvalidForBookingView.vue";
import BackendConnector from "@/util/BackendConnector";

export const openPrivateEventRouter = {
  path: "/open-private-event/shop-:shopId",
  component: BaseEvent,
  children: [
    {
      path: "",
      name: "openPrivateEventLineConnect",
      component: LineConnectView,
      props: {
        loadData: (shopId) => BackendConnector.loadOpenPrivateConfig(shopId),
        liffId: (data) => data.data.liff_id,
        next: (route, memberId) => {
          return { name: "openPrivateInfo", query: { memberId: memberId } };
        },
      },
    },
    {
      path: "open-private-info",
      name: "openPrivateInfo",
      component: OpenPrivateInfoView,
    },
    {
      path: "booking-:bookingId",
      name: "openPrivateSummary",
      component: OpenPrivateSummaryView,
    },
    {
      path: "invalid-for-booking",
      name: "invalidForBooking",
      component: InvalidForBookingView,
    },
  ],
};
