<template>
  <v-sheet class="text-start" v-if="branchs">
    <page-head
      sub-head="report"
      title="Issue Report"
      :breadcrumb="['Class', 'Report', 'Issue Report']"
    >
    </page-head>
    <v-form class="ma-3" ref="form" @submit.prevent="onFormSubmit">
      <h2 class="my-5">Filter</h2>
      <v-select
        label="Branch"
        variant="outlined"
        :items="branchs"
        v-model="branch"
      ></v-select>
      <v-select
        label="Month"
        variant="outlined"
        :items="months"
        v-model="month"
      ></v-select>
      <v-btn class="mt-2" type="submit" variant="outlined" block color="primary"
        >Send Report to Email</v-btn
      >
    </v-form>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { authAxios } from "@/util/Axios";
import moment from "moment";
import { ref } from "vue";

const axios = authAxios();
const branchs = ref(null);
const months = ref(null);

const branch = ref(null);
const month = ref(null);

months.value = generateMonth();

function generateMonth() {
  let today = moment(new Date());
  let result = [];
  for (let i = 0; i < 6; i++) {
    result.push({
      title: today.format("MMM YYYY"),
      value: today.format("MM/YYYY"),
    });
    today.subtract(1, "months");
  }
  return result;
}

loadBranchs().then((response) => {
  var result = [{ title: "All", value: "__all__" }];
  response.data.data.forEach((branch) => {
    result.push({ title: branch.name, value: branch.id });
  });
  branchs.value = [...result];
});

function loadBranchs() {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/shop_branch/`,
  });
}
function onFormSubmit() {
  issueReport(branch.value, month.value).then(() => {});
}

function issueReport(branch, month) {
  let branch_id = branch == "__all__" ? null : branch;
  console.log("Branch: ", branch_id);
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/classroom_booking/issue_report`,
    data: { branch_id, month },
  });
}
</script>
