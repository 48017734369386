<template>
  <v-sheet class="text-start">
    <page-head
      title="Create Fund Package"
      :breadcrumb="['Market', 'Fund Packages', 'Create']"
    >
    </page-head>
    <v-form class="ma-3" ref="form" @submit.prevent="onFormSubmit">
      <h2 class="my-5">Package</h2>
      <v-text-field
        label="Name"
        :rules="RULES.notEmpty"
        v-model="nameValue"
        variant="outlined"
      ></v-text-field>
      <v-text-field
        label="Description"
        v-model="descriptionValue"
        variant="outlined"
      ></v-text-field>
      <v-text-field
        label="Price"
        :rules="RULES.digit"
        v-model="priceValue"
        variant="outlined"
      ></v-text-field>
      <v-btn class="mt-2" type="submit" variant="outlined" block color="primary"
        >Create</v-btn
      >
    </v-form>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import { authAxios } from "@/util/Axios";
import { ref } from "vue";
import { RULES } from "@/util/Form";
import { useRouter } from "vue-router";

const axios = authAxios();
const router = useRouter();

const form = ref(null);
const nameValue = ref("");
const descriptionValue = ref("");
const priceValue = ref(null);

async function onFormSubmit() {
  const { valid } = await form.value.validate();
  if (valid) {
    submitCreatePackage(
      nameValue.value,
      descriptionValue.value,
      priceValue.value
    ).then(() => {
      router.go(-1);
    });
  }
}

function submitCreatePackage(name, description, price) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/sale_package`,
    data: { name, description, price },
  });
}
</script>
