<template>
  <v-navigation-drawer
    class="pa-0"
    width="300"
    :temporary="$vuetify.display.mdAndDown"
  >
    <v-img
      :src="require('@/assets/img/logo_booking_hoz.svg')"
      class="mx-15 my-10"
    />
    <v-list class="text-start" color="red-darken-4">
      <v-list-item title="Dashboard" :to="{ name: 'managementDashboard' }">
        <template #prepend>
          <v-icon icon="mdi-home-variant-outline" />
        </template>
      </v-list-item>
      <v-list-subheader>Booking</v-list-subheader>
      <v-list-group v-if="props.shop.feature_enable.class_event">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-school-outline"
            title="Class"
          ></v-list-item>
        </template>
        <v-list-item title="Topics" :to="{ name: 'topicList' }"> </v-list-item>
        <v-list-item title="Instructors" :to="{ name: 'instructorList' }">
        </v-list-item>
        <v-divider class="ms-10 me-3"></v-divider>
        <v-list-item title="Report" :to="{ name: 'classReport' }"></v-list-item>
        <v-list-item
          title="Settings"
          :to="{ name: 'classSettings' }"
        ></v-list-item>
      </v-list-group>
      <v-list-group v-if="props.shop.feature_enable.facility_event">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-land-plots"
            title="Facility"
          ></v-list-item>
        </template>
        <v-list-item
          title="Resources"
          :to="{ name: 'resourceList' }"
        ></v-list-item>
        <v-divider class="ms-10 me-3"></v-divider>
        <v-list-item
          title="Report"
          :to="{ name: 'facilityReport' }"
        ></v-list-item>
        <v-list-item
          title="Settings"
          :to="{ name: 'facilitySettings' }"
        ></v-list-item>
      </v-list-group>
      <v-list-group v-if="props.shop.feature_enable.open_private_event">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-lock-open-outline"
            title="Open Private"
          ></v-list-item>
        </template>
        <v-list-item title="Topics" :to="{ name: 'topicList' }"> </v-list-item>
        <v-list-item title="Instructors" :to="{ name: 'instructorList' }">
        </v-list-item>
        <v-divider class="ms-10 me-3"></v-divider>
        <v-list-item
          title="Report"
          :to="{ name: 'openPrivateReport' }"
        ></v-list-item>
        <v-list-item
          title="Settings"
          :to="{ name: 'openPrivateSettings' }"
        ></v-list-item>
      </v-list-group>
      <v-list-subheader>CRM</v-list-subheader>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-crown-circle-outline"
            title="Membership"
          ></v-list-item>
        </template>
        <v-list-item title="Funds" :to="{ name: 'fundList' }"> </v-list-item>
      </v-list-group>
      <v-list-group
        v-if="
          props.shop.feature_enable.member_wallet ||
          props.shop.feature_enable.package_purchasing
        "
      >
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-treasure-chest-outline"
            title="Market"
          ></v-list-item>
        </template>
        <v-list-item
          title="TopUp Packages"
          :to="{ name: 'topUpList' }"
          v-if="props.shop.feature_enable.member_wallet"
        >
        </v-list-item>
        <v-list-item
          title="Fund Packages"
          :to="{ name: 'packageList' }"
          v-if="props.shop.feature_enable.package_purchasing"
        >
        </v-list-item>
        <v-list-item
          title="Promotion Groups"
          :to="{ name: 'promotionGroupList' }"
          v-if="props.shop.feature_enable.package_purchasing"
        >
        </v-list-item>
      </v-list-group>
      <v-list-subheader>Configuration</v-list-subheader>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-store-outline"
            title="Shop"
          ></v-list-item>
        </template>
        <v-list-item title="Branchs" :to="{ name: 'branchList' }"></v-list-item>
        <v-list-item
          v-if="props.shop.feature_enable.facility_event"
          title="Workdays"
          :to="{ name: 'workdayList' }"
        ></v-list-item>
        <v-list-item title="Teams" :to="{ name: 'teamList' }"></v-list-item>
        <v-list-item
          title="Settings"
          :to="{ name: 'shopSettings' }"
        ></v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import { defineProps } from "vue";
const props = defineProps(["shop"]);
</script>
