<template>
  <v-layout class="rounded rounded-md" v-if="shopDetail">
    <router-view
      name="sideBar"
      v-model="isDrawerVisibleOnMobile"
      :shop="shopDetail"
    />
    <router-view name="appBar" @drawer-icon-clicked="onDrawerShow" />
    <v-main style="min-height: 300px">
      <router-view :shop="shopDetail" />
    </v-main>
  </v-layout>
</template>

<script setup>
import { ref } from "vue";
import { useDisplay } from "vuetify/lib/framework.mjs";
import { useUserProfile } from "@/util/UserProfile.js";
import BackendConnector from "@/util/BackendConnector";
import { useRoute, useRouter } from "vue-router";

const shopDetail = ref(null);
const route = useRoute();
const router = useRouter();

const display = useDisplay();
const isDrawerVisibleOnMobile = ref(!display.mdAndDown.value);

function onDrawerShow() {
  isDrawerVisibleOnMobile.value = true;
}

const userProfile = ref({
  userEmail: "xxyy@zzzaa.com",
  currentShopName: "My Shop",
  currentShopId: "my_shop",
});

useUserProfile()
  .then((response) => {
    userProfile.value = response;
    BackendConnector.loadShopDetail(userProfile.value.currentShopId).then(
      (r) => {
        shopDetail.value = r.data;
      }
    );
  })
  .catch((error) => {
    if (error.response.status == 405) {
      navigateToLogin();
    }
  });

function navigateToLogin() {
  let path = route.fullPath.split("/");
  console.log(path[1]);
  if (path[1] == "frontdesk") {
    router.push({ name: "frontdeskLogin" });
  } else if (path[1] == "management") {
    router.push({ name: "managementLogin" });
  } else if (path[1] == "hq") {
    router.push({ name: "hqLogin" });
  }
}
</script>

<style scoped>
.app-bar {
  text-align: left;
}
</style>
