<template>
  <v-list-item>
    <template #prepend>
      <v-icon icon="mdi-calendar" class="list-prepend-icon"></v-icon>
    </template>
    <template #append v-if="props.allowCancel">
      <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn
            v-bind="activatorProps"
            icon="mdi-trash-can-outline"
            variant="outlined"
            color="error"
          ></v-btn>
        </template>
        <template v-slot:default="{ isActive }">
          <v-card title="Class Cancel Confirmation">
            <v-card-text>
              Please confirm that you really want to cancel this class?
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text="Cancel Class"
                @click="
                  isActive.value = false;
                  emits('cancel', props.booking);
                "
              ></v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </template>
  </v-list-item>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const props = defineProps(["booking", "allowCancel"]);
const emits = defineEmits(["cancel"]);
</script>
