<template>
  <app-bar title="Booking Summary" :member-id="bookingInfo.memberId" />
  <v-overlay :model-value="isLoading" class="align-center justify-center">
    <v-progress-circular color="white" size="64" indeterminate />
  </v-overlay>
  <transition name="fade">
    <div class="booking-card elevation-2">
      <v-container class="px-0">
        <div class="d-flex justify-space-between align-center">
          <v-sheet class="text-end">
            <v-img
              :src="ui.squareLogoLink"
              width="128"
              rounded="xl"
              class="mt-n5"
            />
          </v-sheet>
          <v-sheet class="text-end">
            <p>{{ bookingInfo.shop }}</p>
            <booking-status-display :status="bookingInfo.status" />
          </v-sheet>
        </div>
      </v-container>
      <v-divider class="border-opacity-50 booking-divider" />
      <v-container class="py-6">
        <v-row>
          <v-col class="text-left pa-0"> Attendee </v-col>
          <v-col class="text-right pa-0">
            {{ bookingInfo.attendee }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pa-0"> Branch </v-col>
          <v-col class="text-right pa-0">
            {{ bookingInfo.branch }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pa-0"> Instructor </v-col>
          <v-col class="text-right pa-0">
            {{ bookingInfo.instructor }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pa-0"> Class </v-col>
          <v-col class="text-right pa-0">
            {{ bookingInfo.topic }}
          </v-col>
        </v-row>
        <v-row>
          <v-col class="text-left pa-0"> DateTime </v-col>
          <v-col class="text-right pa-0">
            {{ bookingInfo.datetime }}
          </v-col>
        </v-row>
      </v-container>
      <v-divider class="border-opacity-50 booking-divider" />
      <v-container v-if="bookingInfo.status === 'completed'">
        <v-row>
          <v-col auto class="pa-0" />
          <div class="flex-shink-1">
            <qr-code
              :text="'BECG:' + bookingInfo.bookingId"
              class="d-flex pa-5 qr-code-div"
              :size="220"
            />
            <p class="pb-5 qr-text">
              {{ bookingInfo.bookingId }}
            </p>
          </div>
          <v-col auto class="pa-0" />
        </v-row>
      </v-container>
    </div>
  </transition>
</template>

<script setup>
import { reactive, ref } from "vue";
import { useRoute } from "vue-router";
import AppBar from "@/components/AppBar.vue";
import BookingStatusDisplay from "@/components/BookingStatusDisplay.vue";
import { useUi } from "@/util/ShopUi";
import axios from "axios";
import moment from "moment";

const route = useRoute();
const ui = ref({});
useUi(route.params.shopId).then((responseUi) => {
  ui.value = responseUi;
});

const isLoading = ref(true);

const bookingInfo = reactive({
  bookingId: route.params.bookingId,
  memberId: "",
  shopId: "",
  shop: "Booking Engine",
  branch: "Bangkok",
  instructor: "Chet Chetchaiyan",
  topic: "Python 101",
  datetime: "Jan 01, 2024 18:00",
  status: "completed",
});

loadBooking(route.params.bookingId).then((response) => {
  isLoading.value = false;
  bookingInfo.eventId = response.data.event_id;
  bookingInfo.shopId = response.data.shop_id;
  bookingInfo.shop = response.data.shop;
  bookingInfo.memberId = response.data.member_id;
  bookingInfo.instructor = response.data.instructor;
  bookingInfo.attendee = response.data.attendee;
  bookingInfo.branch = response.data.branch;
  bookingInfo.topic = response.data.topic;
  bookingInfo.datetime = moment(response.data.datetime).format(
    "MMM D, YYYY HH:mm"
  );
  bookingInfo.status = response.data.status;
  console.log("Booking Info: ", bookingInfo);
});

async function loadBooking(bookingId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/open_private_booking/id-${bookingId}`,
  });
}
</script>

<style scoped>
.booking-card {
  margin: 2rem 1rem;
  padding: 2rem;
  background: white;
}

.booking-divider {
  border-style: dashed;
}

.qr-text {
  font-weight: 600;
  font-size: 1.5rem;
}
</style>
