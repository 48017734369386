<template>
  <v-navigation-drawer
    class="pa-0"
    width="300"
    :temporary="$vuetify.display.mdAndDown"
  >
    <v-img
      :src="require('@/assets/img/logo_booking_hoz.svg')"
      class="mx-15 my-10"
    />
    <v-list class="text-start" color="red-darken-4">
      <v-list-item
        title="Classroom Welcome"
        :to="{ name: 'frontdeskClassroomWelcome' }"
        v-if="props.shop.feature_enable.class_event"
      >
        <template #prepend><v-icon icon="mdi-human-male-board" /></template>
      </v-list-item>
      <v-list-item
        title="Facility Welcome"
        :to="{ name: 'frontdeskFacilityWelcome' }"
        v-if="props.shop.feature_enable.facility_event"
      >
        <template #prepend><v-icon icon="mdi-land-plots" /></template>
      </v-list-item>
      <v-list-item
        title="Open Private Welcome"
        :to="{ name: 'frontdeskOpenPrivateWelcome' }"
        v-if="props.shop.feature_enable.open_private_event"
      >
        <template #prepend><v-icon icon="mdi-lock-open-outline" /></template>
      </v-list-item>
      <v-list-item title="Membership" :to="{ name: 'frontdeskMembership' }">
        <template #prepend><v-icon icon="mdi-wallet-membership" /></template>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps(["shop"]);
console.log(props.shop.feature_enable);
</script>
