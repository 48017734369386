import MembershipLandingView from "@/views/membership/LandingView.vue";
import BaseMembershipView from "@/views/membership/BaseMembershipView.vue";
import MembershipPurchasePackageView from "@/views/membership/MembershipPurchasePackageView.vue";
import MembershipPurchasePackageSummaryView from "@/views/membership/MembershipPurchasePackageSummaryView.vue";
import MembershipHistoryView from "@/views/membership/MembershipHistoryView.vue";
import MembershipFacilityHistoryView from "@/views/membership/MembershipFacilityHistoryView.vue";
import MembershipOpenPrivateHistoryView from "@/views/membership/MembershipOpenPrivateHistroyView.vue";
import MembershipUpcomingView from "@/views/membership/MembershipUpcomingView.vue";
import MembershipSettingView from "@/views/membership/MembershipSettingView.vue";
import MembershipWalletTopUpView from "@/views/membership/WalletTopUpView.vue";
import MembershipWalletTopUpSummaryView from "@/views/membership/WalletTopUpSummaryView.vue";
import MembershipWalletHistoryView from "@/views/membership/WalletHistoryView.vue";

import RegisterView from "@/views/membership/RegisterView.vue";
import LineConnectView from "@/views/LineConnectView.vue";
import BackendConnector from "@/util/BackendConnector";

export const membershipLineConnectRouter = {
  path: "/membership/shop-:shopId",
  name: "membershipLineConnect",
  component: LineConnectView,
  props: {
    loadData: (shopId) => BackendConnector.loadClassroomInitialData(shopId),
    liffId: (data) => data.membership_liff_id,
    next: (route, memberId) => {
      let result = {
        name: "memberLanding",
        params: { shopId: route.params.shopId, memberId },
      };
      return result;
    },
  },
};

export const membershipRouter = {
  path: "/membership/shop-:shopId",
  component: BaseMembershipView,
  children: [
    {
      path: "register",
      name: "membershipRegister",
      component: RegisterView,
    },
    {
      path: "id-:memberId",
      name: "memberLanding",
      component: MembershipLandingView,
    },
    {
      path: "id-:memberId/package",
      name: "membershipPurchasePackage",
      component: MembershipPurchasePackageView,
    },
    {
      path: "id-:memberId/order/id-:orderNo/summary",
      name: "membershipPurchasePackageSummary",
      component: MembershipPurchasePackageSummaryView,
    },
    {
      path: "id-:memberId/history",
      name: "membershipHistory",
      component: MembershipHistoryView,
    },
    {
      path: "id-:memberId/facilityHistory",
      name: "membershipFacilityHistory",
      component: MembershipFacilityHistoryView,
    },
    {
      path: "id:-:memberId/openPrivateHistory",
      name: "membershipOpenPrivateHistory",
      component: MembershipOpenPrivateHistoryView,
    },
    {
      path: "id-:memberId/upcoming",
      name: "membershipUpcoming",
      component: MembershipUpcomingView,
    },
    {
      path: "id-:memberId/setting",
      name: "membershipSetting",
      component: MembershipSettingView,
    },
    {
      path: "id-:memberId/wallet-top-up",
      name: "membershipWalletTopUp",
      component: MembershipWalletTopUpView,
    },
    {
      path: "id-:memberId/top-up/id-:orderNo/summary",
      name: "membershipWalletTopUpSummary",
      component: MembershipWalletTopUpSummaryView,
    },
    {
      path: "id-:memberId/wallet-history",
      name: "membershipWalletHistory",
      component: MembershipWalletHistoryView,
    },
  ],
};
