<template>
  <v-sheet class="text-start" v-if="instructor">
    <page-head
      :title="instructor.name"
      sub-head="instructors"
      :breadcrumb="['Class', 'Instructors', instructor.name]"
    >
    </page-head>
    <v-tabs v-model="tab" color="red-darken-4">
      <v-tab value="detail">Detail</v-tab>
      <v-tab
        value="schedule"
        v-if="props.shop.feature_enable.open_private_event"
        >Schedule</v-tab
      >
    </v-tabs>
    <v-card-text>
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="detail">
          <v-container fluid>
            <patch-input
              label="ID"
              :default="instructor.id"
              :readonly="true"
            ></patch-input>
            <patch-input
              label="Name"
              patch-key="name"
              :default="instructor.name"
              :update-func="onUpdate"
            ></patch-input>
          </v-container>
        </v-tabs-window-item>
        <v-tabs-window-item value="schedule">
          <v-data-table-server
            :items="dataTable.items"
            :items-per-page="60"
            :items-length="60"
            :headers="dataTable.headers"
            :loading="dataTable.isLoading"
            :items-per-page-options="[60]"
            @update:options="updateSchedule"
          >
            <template v-slot:[`item.action`]="{ item }">
              <v-dialog max-width="500">
                <template v-slot:activator="{ props: activatorProps }">
                  <v-btn v-bind="activatorProps"> Edit </v-btn>
                </template>
                <template v-slot:default="{ isActive }">
                  <v-card :title="`Edit Schedule ${item.date}`">
                    <v-card-text>
                      <v-checkbox
                        label="Available"
                        v-model="available"
                      ></v-checkbox>
                      <v-select
                        label="Start Time"
                        :items="TIME"
                        variant="outlined"
                        v-model="startTime"
                      ></v-select>
                      <v-select
                        label="End Time"
                        :items="TIME"
                        variant="outlined"
                        v-model="endTime"
                      ></v-select>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text="Cancel"
                        @click="isActive.value = false"
                      ></v-btn>
                      <v-btn
                        text="Save"
                        @click="
                          onUpdateSchedule(item.date);
                          isActive.value = false;
                        "
                      ></v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>
          </v-data-table-server>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-card-text>
  </v-sheet>
</template>

<script setup>
import PageHead from "@/components/admin_theme/PageHead.vue";
import PatchInput from "@/components/admin_theme/patch/PatchInput.vue";

import { authAxios } from "@/util/Axios";
import moment from "moment";

import { defineProps, reactive, ref } from "vue";
import { useRoute } from "vue-router";

const tab = ref(null);

const props = defineProps(["shop"]);
const axios = authAxios();
const route = useRoute();

const instructor = ref(null);
const dataTable = reactive({
  headers: [
    { title: "Date", key: "date", align: "start", sortable: false },
    {
      title: "available",
      key: "available",
      align: "start",
      sortable: false,
    },
    { title: "Start Time", key: "start_time", align: "start", sortable: false },
    { title: "End Time", key: "end_time", align: "start", sortable: false },
    { title: "Action", key: "action", align: "start", sortable: false },
  ],
});

const TIME = [];
for (let i = 0; i < 48; i++) {
  let hour = (i * 30) / 60;
  let minute = (i * 30) % 60;
  TIME.push(moment(`${hour}:${minute}`, "H:mm").format("HH:mm"));
}
const available = ref(null);
const startTime = ref(null);
const endTime = ref(null);

loadInstructor(route.params.instructorId).then((response) => {
  instructor.value = response.data;
});

function loadInstructor(instructorId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/instructor/id-${instructorId}`,
  });
}

function onUpdate(key, value) {
  return axios({
    method: "PATCH",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/instructor/id-${route.params.instructorId}`,
    data: { [key]: value },
  });
}

function updateSchedule() {
  dataTable.isLoading = true;
  loadInstructorSchedule(route.params.instructorId).then((response) => {
    dataTable.isLoading = false;
    dataTable.items = response.data;
  });
  console.log("updateSchedule");
}

function loadInstructorSchedule(instructorId) {
  return axios({
    method: "GET",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/instructor/id-${instructorId}/schedule`,
  });
}

function onUpdateSchedule(date) {
  saveSchedule(
    route.params.instructorId,
    date,
    available.value,
    startTime.value,
    endTime.value
  ).then(() => {
    updateSchedule();
  });
}

function saveSchedule(instructorId, date, available, startTime, endTime) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/instructor/id-${instructorId}/schedule`,
    data: { date, available, start_time: startTime, end_time: endTime },
  });
}
</script>
