<template>
  <app-bar title="Facility History" :member-id="$route.params.memberId" />
  <v-list class="bg-transparent text-start" lines="two">
    <member-history-item
      v-for="booking in bookingList"
      :key="booking.booking_id"
      :title="`${booking.resource} (${booking.startTimeStr})`"
      :subtitle="moment(booking.date).format('MMM DD, YYYY')"
      :booking="booking"
      :allow-cancel="booking.is_allow_cancel"
      @cancel="onCancelFacility"
    ></member-history-item>
  </v-list>
  <p v-if="bookingList.length == 0" class="mt-5">No Facility Booked</p>
</template>

<script setup>
import moment from "moment";
import AppBar from "@/components/AppBar.vue";
import MemberHistoryItem from "@/components/MemberHistoryItem.vue";
import BackendConnector from "@/util/BackendConnector";

import { ref } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";

const route = useRoute();
const bookingList = ref([]);

init();

function init() {
  BackendConnector.loadFacilityBookingHistory(route.params.memberId).then(
    (response) => {
      bookingList.value = response.data;
      bookingList.value.forEach((booking) => {
        let t = [];
        booking.start_time.forEach((element) => {
          t.push(moment(element, "HH:mm:ss").format("HH:mm"));
        });
        booking.startTimeStr = t;
      });
    }
  );
}

function onCancelFacility(booking) {
  console.log(booking);
  bookingList.value = [];
  cancelFacilityBooking(booking.booking_id).then(() => {
    init();
  });
}

function cancelFacilityBooking(bookingId) {
  return axios({
    method: "POST",
    url: `${process.env.VUE_APP_BACKEND_URL}/api/facility_booking/id-${bookingId}/cancel`,
  });
}
</script>

<style scoped>
.list-prepend-icon {
  font-size: 2.4rem;
}
</style>
